import React, { useEffect, useState, useImperativeHandle } from 'react';
import styles from './pattern_list.module.css'
import {Spin} from 'antd'
import * as apis from '../../api'
let page = 1
const PatternList = (props) => {
    const [show,setShow] = useState(false)
    const [filtersTp,setFiltersTp] = useState([])
    const [filtersStyle,setFiltersStyle] = useState([])
    const [patternList,setPatternList] = useState([])
    const [loading,setLoading] = useState(false)
    const [total,setTotal] = useState(0)
    const [currentTp,setCurrentTp] = useState(0)
    const [currentStyle,setCurrentStyle] = useState(0)
    

    useImperativeHandle(props.cRef, () => ({
        do: async () => {
            //setLoading(true)
            setShow(true)
        }
    }));

    const choiceStyleItem = async (val) => {
        setCurrentStyle(val.value)
        setPatternList([])
        page = 1
        await loadMoreData(page,currentTp,val.value,[])
    }

    const choiceItem = async (val) => {
        setCurrentTp(val.value)
        setPatternList([])
        page = 1
        await loadMoreData(page,val.value,currentStyle,[])
    }

    const loadMoreData = async (page,tp,style,paramPatternList) => {
        setLoading(true)
        let cp = [...paramPatternList]
        let patternData = await apis.getPatternList({page,page_size:20,search:{tp,style}})
        setPatternList([...cp,...patternData.base_pattern_list || []])
        setTotal(patternData.total)
        setLoading(false)   
    }

    const choicePatternItem = (item) => {
        props.onChange(item)
        setShow(false)
    }
    

    return (
        <>
            <div className={styles.patternBox} style={{display:show ? 'block':'none'}}>
                <div>图片分类</div>
                <img style={{width: 150}} src="./test_objs/780af2630bbe4aed23925b6aff7785c1.jpeg" onClick={()=>{
                    props.onChange("./test_objs/780af2630bbe4aed23925b6aff7785c1.jpeg")
                    setShow(false)
                }} />
            </div>
        </>
    )

}

export default PatternList