import styles from './index.module.css'
import addImgIcon from '../../static/images/1621417489257.jpg'
import oss from 'ali-oss'
import * as apis from '../../api'
import { message } from 'antd'
import moment from 'moment'
import { recursiveAdd } from './pattern'
import { addFabic } from './fabric'

const client = new oss({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: 'oss-cn-beijing',
    // 从STS服务获取的临时访问凭证。临时访问凭证包括临时访问密钥（AccessKeyId和AccessKeySecret）和安全令牌（SecurityToken）。
    accessKeyId: process.env.REACT_APP_OSS_ID,
    accessKeySecret: process.env.REACT_APP_OSS_SECRET,
    // 填写Bucket名称。
    bucket: 'jihe-file'
});

export const menus = [
    {
        "label":"面料",
        "value":1,
        "options":[
            {
                "label":"预选面料",
                "value":1
            },
            {
                "label":"推荐面料",
                "value":2
            }
        ]
    },
    {
        "label":"纹样",
        "value":2,
        "options":[
            {
                "label":"预选纹样",
                "value":1
            },
            {
                "label":"纹样库",
                "value":2
            },
            {
                "label":"我的纹样",
                "value":3
            }
        ]
    }
]

export const renderHtml = (at,data = [],callBack) => {
    //纹样库
    if(`2_2` === at || `2_1` === at){
        return (
            <div>
                {
                    data.map((item,dx)=>(
                        <div key={dx} className={styles.flowItem}>
                            <img src={`${item.url}?x-oss-process=image/resize,h_200,m_lfit`} onClick={()=>{
                                window.showLoading()
                                recursiveAdd(`${item.url}?x-oss-process=image/resize,h_600,m_lfit`,progress => {
                                    
                                },()=>{
                                    window.hideLoading()
                                },(err)=>{
                                    message.error(err)
                                    window.hideLoading()
                                })
                            }} style={{width:75,height:75,cursor:'pointer'}} alt="" />
                            <div style={{height:5}}></div>
                            <div style={{color: 'rgba(255, 255, 255, 0.7)',fontSize: 12,textAlign: 'center',width: '100%',height: 18,overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{item.name}</div>
                        </div>
                    ))
                }
                <div style={{display: `2_1` === at ? 'none':'block'}} className={styles.flowItem}>
                    <img src={addImgIcon} onClick={()=>{
                        callBack('pattern_more')
                    }} style={{width:75,height:75,cursor:'pointer'}} alt="" />
                    <div style={{height:5}}></div>
                    <div style={{color: 'rgba(255, 255, 255, 0.7)',fontSize: 12,textAlign: 'center'}}>查看更多</div>
                </div>
            </div>
        )
    }else if(`2_3` === at){
        return (
            <div>
                <div className={styles.flowItem}>
                    <input onChange={async (file)=>{
                        if(!file){
                            return
                        }
                        try {
                            try{
                                file = file.target.files[0]
                                var index = file.name.lastIndexOf(".");
                                var ext = file.name.substr(index+1);
                            }catch(e){
                                message.error('请上传正确的格式文件')
                                return
                            }
                            window.showLoading()
                            // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
                            // 您可以通过自定义文件名（例如exampleobject.txt）或目录（例如mytestdoc/exampleobject.txt）的形式实现将文件上传到当前Bucket或Bucket中的指定目录。
                            let path = `${moment().format("YYYY-MM-DD")}/${new Date().getTime()}`
                            await client.put(`${path}.${ext}`, file);
                            await apis.createDiyPattern({name:`DIY-${new Date().getTime()}`,url:`https://files.dizanz.com/${path}.${ext}`})
                            callBack('refresh_diy_pattern_list')
                        } catch (e) {
                            message.error("上传失败")
                        }
                    }} type="file" id="upload_pattern" style={{display:'none'}} />
                    <label htmlFor="upload_pattern">
                        <img src={addImgIcon} style={{width:75,height:75,cursor:'pointer'}} alt="" />
                    </label>
                    <div style={{height:5}}></div>
                    <div style={{color: 'rgba(255, 255, 255, 0.7)',fontSize: 12,textAlign: 'center'}}>上传纹样</div>
                </div>
                {
                    data.map((item,dx)=>(
                        <div key={dx} className={styles.flowItem}>
                            <img src={`${item.url}?x-oss-process=image/resize,h_200,m_lfit`} onClick={()=>{
                                window.showLoading()
                                recursiveAdd(`${item.url}?x-oss-process=image/resize,h_600,m_lfit`,progress => {
                                    
                                },()=>{
                                    window.hideLoading()
                                },(err)=>{
                                    message.error(err)
                                    window.hideLoading()
                                })
                            }} style={{width:75,height:75,cursor:'pointer'}} alt="" />
                            <div style={{height:5}}></div>
                            <div style={{color: 'rgba(255, 255, 255, 0.7)',fontSize: 12,textAlign: 'center', width: '100%',height: 18,overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{item.name}</div>
                        </div>
                    ))
                }
            </div>
        )
    }else if(`1_1` === at){
        return (
            <div>
                {
                    data.map((item,dx)=>(
                        <div key={dx} className={styles.flowItem} onClick={()=>{
                            addFabic(item)
                            callBack('update_fabric')
                        }}>
                            <img src={item.url} style={{width:75,height:75,cursor:'pointer'}} alt="" />
                            <div style={{height:5}}></div>
                            <div style={{color: 'rgba(255, 255, 255, 0.7)',fontSize: 12,textAlign: 'center',width: '100%',height: 18,overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{item.name}</div>
                        </div>
                    ))
                }
            </div>
        )
    }else if(`1_2` === at){
        return (
            <div>
                {
                    data.map((item,dx)=>(
                        <div key={dx} className={styles.flowItem} onClick={()=>{
                            addFabic(item)
                            callBack('update_fabric')
                        }}>
                            <img src={item.url} style={{width:75,height:75,cursor:'pointer'}} alt="" />
                            <div style={{height:5}}></div>
                            <div style={{color: 'rgba(255, 255, 255, 0.7)',fontSize: 12,textAlign: 'center',width: '100%',height: 18,overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>{item.name}</div>
                        </div>
                    ))
                }
            </div>
        )
    }
}