import React, { useState, useImperativeHandle } from 'react';


const Loading = (props) => {
    const [progress,setProgress] = useState(0)
    const [show,setShow] = useState(false)

    useImperativeHandle(props.cRef, () => ({
        do:(val) => {
            setProgress(val)
        },
        display:(val) => {
            if(!val){
                setTimeout(()=>{
                    setShow(val)
                },500)
            }else{
                setShow(val)
            }
        }
    }));

    return (
        <div style={{display:show ? 'block':'none',position:'fixed',left:0,top:0,zIndex:10,right:0,bottom:0,margin:'auto'}}>
            <div style={{width:300,height:27,border: 'solid 1px #000',position: 'fixed',left: 0,right: 0,top: 0,bottom: 0,margin: 'auto',borderRadius: '3px',backgroundColor:'rgb(75,75,75)'}}>
                <div style={{float:'left',width:progress*3-2,height:25,borderRadius:'3px',backgroundImage: 'linear-gradient(rgb(132,215,250), rgb(69,171,229))',color: '#FFF',lineHeight: '25px',textIndent: '5px'}}>
                    ...{parseInt((progress*3)/300*100)}%
                </div>
            </div>
        </div>
    )
}

export default Loading