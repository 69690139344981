import { fabric } from "fabric";
import {getBlob,saveAs} from '../../common/down_load'
const { FABRICCANVANS } = window

function setImgLine(url,code){
    fabric.Image.fromURL(url, (img) => {
        img.scaleToWidth(600,false)
        FABRICCANVANS[code].setOverlayImage(img, FABRICCANVANS[code].renderAll.bind(FABRICCANVANS[code]))
        // MAPS[code].needsUpdate = true
    },{ crossOrigin: 'anonymous' })
}

function loadCp(code){
    FABRICCANVANS[code] = new fabric.Canvas(code)
    FABRICCANVANS[code].controlsAboveOverlay = true
    FABRICCANVANS[code].preserveObjectStacking = true
    FABRICCANVANS[code].selection = false
    if(window.CODECPS[code].background_color){
        FABRICCANVANS[code].backgroundColor = window.CODECPS[code].background_color
    }else{
        FABRICCANVANS[code].backgroundColor = 'rgba(255,255,255,1)'
    }
    FABRICCANVANS[code].renderAll()

    //注册监听事件
    // FABRICCANVANS[code].on('object:moving',(e) => {
    //     MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
    // })
    // FABRICCANVANS[code].on('object:scaling',(e) => {
    //     MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
    // })
    // FABRICCANVANS[code].on('object:rotating',(e) => {
    //     MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
    // })
    // FABRICCANVANS[code].on('object:moved',(e) => {
    //     MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
    // })
    
 }

 function arrangement(i){
    let num = 8
    let top
    let left = i%num * 670
        left = left + 50
    if(Math.floor(i/num) > 0){
        top = (Math.floor(i/num)) * 930
    }
    if(!top){
        top = 50
    }else{
        top += 50
    }    
    return {left,top}
 }

 function allChoice(data,tp){
    if(tp === 'choice'){
        for(let m=0;m<data.length;m++){
            let dom = window.$(`#cpContentBox_${data[m].code}`)
            dom.css({border: 'solid rgba(39, 39, 40, 1)'})
            let dx = window.cpChoiced.findIndex(item => {
                return item === data[m].code
            })
            if(dx === -1){
                window.cpChoiced.push(data[m].code)
            }
        }
    }else{
        for(let m=0;m<window.cpChoiced.length;m++){
            let dom = window.$(`#cpContentBox_${window.cpChoiced[m]}`)
            dom.css({border: 'none'})
        }
        window.cpChoiced = []
    }
 }

 function recursiveLoadCp(data){
    for (let i=0;i<data.length;i++){
        let {left,top} = arrangement(i)
        window.$(`#cpContentBox_${data[i].code}`).css({left,top})
        loadCp(data[i].code)
    }
 }

 const statusTxts = {"1":"待计算","2":"计算中","3":"取消计算","4":"计算失败","5":"计算完成"}

 function calculateStatusTxt(code,status){
     if(!status){
        return (<p style={{fontSize: 30,color: '#333333',textAlign: 'center',margin: '18px 0px'}}>{code} - 等待计算</p>)
     }
     if (status === 5){
        return (<p style={{fontSize: 30,color: 'green',textAlign: 'center',margin: '18px 0px'}}>{code} - {statusTxts[status.toString()]}</p>)
     }
     if (status === 4){
        return (<p style={{fontSize: 30,color: 'red',textAlign: 'center',margin: '18px 0px'}}>{code} - {statusTxts[status.toString()]}</p>)
     }
     if (status === 3){
        return (<p style={{fontSize: 30,color: 'darkblue',textAlign: 'center',margin: '18px 0px'}}>{code} - {statusTxts[status.toString()]}</p>)
     }
     if (status === 2){
        return (<p style={{fontSize: 30,color: 'deeppink',textAlign: 'center',margin: '18px 0px'}}>{code} - {statusTxts[status.toString()]}</p>)
     }
     if (status === 1){
        return (<p style={{fontSize: 30,color: '#333333',textAlign: 'center',margin: '18px 0px'}}>{code} - {statusTxts[status.toString()]}</p>)
     }
 }

 function down_load_cp(item){
    if(item.img_line){
       getBlob(item.img_line, (blob) => {
        saveAs(blob, `${item.code}.png`);
       });
    }
 }

 export {arrangement,allChoice,recursiveLoadCp,loadCp,calculateStatusTxt,setImgLine,down_load_cp}