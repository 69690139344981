import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import {getRenderFrame} from './render'
import get_camera from './camera'
import gert_scene from './scene'
function controls() {
    if(window.controls){
        return window.controls
    }
    window.controls = new OrbitControls( get_camera(), getRenderFrame().domElement );
    loop_render()
    // window.controls.addEventListener('change', () => {
    //    loop_render()
    // }); // use if there is no animation loop
    // window.controls.minDistance = 0;
    // window.controls.maxDistance = 1000;
    // window.controls.zoom = 12
    //window.controls.enableDamping = true
    // window.controls.target.set( 6.919353456371592, 54.87197817838932, -26.74151715731276 );
    window.controls.target.set( 0, 0.1, 0 );
    return window.controls
}

function loop_render(){
    // if(window.controls){
    //     window.controls.target.set(0,window.controls.target.y, 0);
    // }
    window.controls.update();
    // for(let code in window.MAPS){
    //     if(window.MAPS[code]){
    //         window.MAPS[code].needsUpdate = true
    //     }
    // }
    if(window.ONKEY === "ArrowRight"){
        window.CURRENTPATTERN.left = window.CURRENTPATTERN.left += 1
        window.FABRICCANVANS[window.CURRENTCODE].renderAll()
    }
    if(window.ONKEY === "ArrowLeft"){
        window.CURRENTPATTERN.left = window.CURRENTPATTERN.left -= 1
        window.FABRICCANVANS[window.CURRENTCODE].renderAll()
    }
    if(window.ONKEY === "ArrowUp"){
        window.CURRENTPATTERN.top = window.CURRENTPATTERN.top -= 1
        window.FABRICCANVANS[window.CURRENTCODE].renderAll()
    }
    if(window.ONKEY === "ArrowDown"){
        window.CURRENTPATTERN.top = window.CURRENTPATTERN.top += 1
        window.FABRICCANVANS[window.CURRENTCODE].renderAll()
    }
    requestAnimationFrame(loop_render)
    getRenderFrame().render( gert_scene(), get_camera() );
}

export default controls