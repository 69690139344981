import React, { useState, useImperativeHandle } from 'react';
import { Drawer, Button, Radio, InputNumber, message } from 'antd';
import * as apis from '../../api'
import { screenshots } from './load_obj'

const ConfirmOrder = (props) => {
    const [show,setShow] = useState(false)
    const [value,setValue] = useState(1)
    const [previewOrder,setPreviewOrder] = useState({})
    const [needDetail,setNeedDetail] = useState({})
    const [tempDiscount,setTempDiscount] = useState(100)
    const [price,setPrice] = useState()
    const [diyPrice,setDiyPrice] = useState(false)

    useImperativeHandle(props.cRef, () => ({
        do: async (need_detail) => {
            setNeedDetail(need_detail)
            window.showLoading()
            try {
                await loadData(need_detail,tempDiscount,false,price)
                setShow(true)
            } catch (error) {
                console.log(error)
            }
            window.hideLoading()
        }
    }))

    const makeOrder = async () => {
        window.showLoading()
        setTimeout(async ()=>{
            try {
                let order_ret = await apis.createOrder({need_id: needDetail.detail.id, temp_discount:tempDiscount, pay_method: 1,diy_price:diyPrice,price})
                // window.renderer.domElement.toBlob(async (file)=>{
                //     try {
                //         let path = `order_cover/${order_ret.id}.png`
                //         await client.put(path, file)
                //     } catch (e) {
                //         // message.error("上传失败")
                //     }
                // })
                screenshots(order_ret.id)
                message.success("订单生成成功,请在小程序我的订单里查看")
            } catch (error) {
                message.error("订单生成失败")
            }
            window.hideLoading()
        },500)
    }

    const loadData = async (need_detail,temp_discount,diy_price = false,price) => {
        try {
            let previewOrder = await apis.previewOrder({need_id: need_detail.detail.id, temp_discount, pay_method: 1,diy_price,price})
            if(!previewOrder.royalty_price){
                previewOrder.royalty_price = 0
            }
            setPreviewOrder(previewOrder)
        } catch (error) {
            return false
        }
    }

    const onChange = async (e) => {
        window.showLoading()
        if(e.target.value === 1){
            setDiyPrice(false)
            await loadData(needDetail,100,false,price)
        }else if(e.target.value === 2){
            setDiyPrice(true)
            if(price){
                await loadData(needDetail,100,true,price)
            }else{
                setPrice(previewOrder.base_price)
                await loadData(needDetail,100,true,previewOrder.base_price)
            }
        }
        setValue(e.target.value)
        window.hideLoading()
    }
    const onClose = (e) => {
        setShow(false)
    }
    return (
        <div>
            <Drawer
                title="确认订单"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={show}
                width="50%"
                footer={
                    <div
                        style={{
                        textAlign: 'right',
                        }}
                    >
                        <Button onClick={()=>{
                            setShow(false)
                        }} style={{ marginRight: 8 }}>
                            关闭
                        </Button>
                        <Button onClick={makeOrder} type="primary">
                            确认订单
                        </Button>
                    </div>
                }
            >
                <div>
                    <p>*订单总额不能小于{parseFloat(previewOrder.base_price/100).toFixed(2)}元</p>
                    <Radio.Group onChange={onChange} value={value}>
                        <Radio value={1}>折扣系数</Radio>
                        <Radio value={2}>一口价</Radio>
                    </Radio.Group>
                </div>
                <div style={{display:value === 1 ? 'block':'none'}}>
                    <div style={{height:12}}></div>
                    <InputNumber onBlur={async ()=>{
                        window.showLoading()
                        await loadData(needDetail,tempDiscount,false,price)
                        window.hideLoading()
                    }} value={tempDiscount/100} min={0.1} onChange={(e)=>{
                        setTempDiscount(e * 100)
                    }} />
                </div>
                <div style={{display:value === 2 ? 'block':'none'}}>
                    <div style={{height:12}}></div>
                    <InputNumber style={{width: 110}} onBlur={async (e)=>{
                        if(!price){
                            return
                        }
                        window.showLoading()
                        await loadData(needDetail,100,true,price)
                        window.hideLoading()
                    }} placeholder="填写一口价" value={isNaN(parseFloat(price/100).toFixed(2)) ? undefined:parseFloat(price/100).toFixed(2)} min={previewOrder.base_price/100} onChange={(e)=>{
                        setPrice(parseInt(e * 100))
                    }} />
                </div>
                <div style={{height:12}}></div>
                <div>订单提成：{parseFloat(previewOrder.royalty_price/100).toFixed(2)}元</div>
                <div style={{height:12}}></div>
                <div>订单总额：{parseFloat(previewOrder.price/100).toFixed(2)}元</div>
                <div style={{height:12}}></div>
            </Drawer>
        </div>
    )
}

export default ConfirmOrder