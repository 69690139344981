import * as THREE from 'three/build/three.module.js';

function camera(){
    if(window.camera){
        return window.camera
    }
    window.camera = new THREE.PerspectiveCamera( 45, document.body.clientWidth / (document.body.clientHeight) , 0.25, 2000 );
    window.camera.zoom = 2  
    window.camera.updateProjectionMatrix()
    return window.camera
}

export default camera