let fabricCpCodes = []

export function getFabricCpCodes(){
    return fabricCpCodes
}

export function setFabricCpCodes(item){
    return fabricCpCodes.push(item)
}

export function addFabic(item){
    for(let i=0;i<window.cpChoiced.length;i++){
        let code = window.cpChoiced[i]
        let findedIndex = fabricCpCodes.findIndex(v => {
            return v.code === code
        })
        if(findedIndex === -1){
            fabricCpCodes.push({...item,code})
        }else{
            fabricCpCodes[findedIndex] = {...item,code}
        }
    }
    return fabricCpCodes
}

export function showFabic(code){
    let finfo = fabricCpCodes.find(v => {
        return v.code === code
    })
    if(!finfo){
        return
    }
    return finfo
}

export function saveFabic(code){
    let finfo = fabricCpCodes.find(v => {
        return v.code === code
    })
    if(!finfo){
        return
    }
    return finfo
}