// import cloth_texture from '../../static/images/1622510474076.jpeg'
import * as THREE from 'three/build/three.module.js';
import skin from '../../static/images/skin.png'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { setImgLine } from './cp'
import get_camera from './camera'
import get_scene from './scene'
import { getRenderFrame } from './render'
import oss from 'ali-oss'
import { fabric } from "fabric";

const {MAPS} = window
function loadObj(path,onSuccess,onProgress,onError){
    //new RGBELoader().load( 'https://files.dizanz.com/2021-06-23/royal_esplanade_1k.hdr', function ( texture ) {

        // texture.mapping = THREE.EquirectangularReflectionMapping;

        // get_scene().background = texture;
        // get_scene().environment = texture;
        new OBJLoader().load(path,async objects => {
            window.modelObject = objects
            // get_scene().add(objects)
            // setContent(objects)  
            // for(let m=0;m<objects.children.length;m++){
            //     let child = objects.children[m]
            //     if(child.isMesh){
            //         // if(child.name === "qianxiong"){
            //         //     child.visible = false
            //         //     const subloader = new OBJLoader()
            //         //     subloader.load(`./test_objs/${child.name}3_2.obj?${new Date().getTime()}`, (object) => {
            //         //         let childObject = object.children[0];
            //         //         console.log("sub...",childObject)
            //         //         //childObject.material = new THREE.MeshLambertMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide })
            //         //         //childObject.material.emissive.setHex( 0x11264f )
            //         //         childObject.material.map = new THREE.CanvasTexture(document.getElementById(child.name))
            //         //         childObject.material.map.minFilter = THREE.LinearFilter
            //         //         //移除原裁片
            //         //         window.modelObject.remove(window.MAPS[child.name])
            //         //         //添加新裁片
            //         //         console.log(child.position)
            //         //         // childObject.position.z = 0.58
            //         //         // // childObject.position.x = child.position.x
            //         //         // childObject.position.y = 0.4
            //         //         // childObject.position.x = -0.1
            //         //         window.modelObject.add(childObject)
            //         //         window.MAPS[child.name] = childObject.material.map
            //         //     }) 
            //         // }else{
            //         //     child.material.map = new THREE.CanvasTexture(document.getElementById(child.name))
            //         //     child.material.map.minFilter = THREE.LinearFilter
            //         //     window.MAPS[child.name] = child.material.map
            //         // }
            //         child.material.map = new THREE.CanvasTexture(document.getElementById(child.name))
            //         child.material.map.minFilter = THREE.LinearFilter
            //         window.MAPS[child.name] = child.material.map
            //         window.FABRICCANVANS[child.name] = new fabric.Canvas(child.name)
            //         window.FABRICCANVANS[child.name].enableRetinaScaling = true
            //         window.FABRICCANVANS[child.name].controlAboveOverlay= true
            //         window.FABRICCANVANS[child.name].backgroundColor = '#fff'
            //         window.FABRICCANVANS[child.name].renderAll()
                    
            //     }
            // }
            // onSuccess(objects)
            // console.log(1122,objects)

            // return
            window.modelObject = objects
            get_scene().add(objects)
            setContent(objects)
            objects.traverse(async (child) => {
                if (child.isMesh) {
                    let url_args = new URLSearchParams(window.location.href.split("?")[1])
                    let sub_obj_url = `./test_objs/${child.name}.obj?${new Date().getTime()}`
                    //const subloader = new OBJLoader()
                    //subloader.load(sub_obj_url, (object) => {
                        //let childObject = object.children[0];
                        child.material = new THREE.MeshLambertMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide })
                        // //childObject.material.emissive.setHex( 0x11264f )
                        child.material.map = new THREE.CanvasTexture(document.getElementById(child.name))
                        child.material.map.minFilter = THREE.LinearFilter
                        // //移除原裁片
                        // objects.remove(child.material.map)
                        // // //添加新裁片
                        // objects.add(childObject)
                        window.MAPS[child.name] = child.material.map
                        window.FABRICCANVANS[child.name] = new fabric.Canvas(child.name)
    
               
    
                        // window.FABRICCANVANS[child.name].enableRetinaScaling = true
                        // window.FABRICCANVANS[child.name].controlAboveOverlay= true
    
                        window.FABRICCANVANS[child.name].backgroundColor = '#fff'
                        window.FABRICCANVANS[child.name].renderAll()
                        // fabric.Image.fromURL(`https://files.dizanz.com/2021-09-27/1632723468234.png?x-oss-process=image/resize,h_200,m_lfit`, (img) => {
                        //     window.FABRICCANVANS[child.name].add(img)
                        // },{ crossOrigin: 'anonymous' })
                        // fabric.Image.fromURL(`./test_objs/objs.png`, (img) => {
                        //     console.log(img)
                        //     // window.FABRICCANVANS[child.name].add(img)
                        //     window.FABRICCANVANS[child.name].backgroundImage = img
                        //     //window.FABRICCANVANS[child.name].renderAll()
                        // },{ crossOrigin: 'anonymous' })
                    //})
    
    
    
                    // child.material = new THREE.MeshLambertMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide })
                    // window.FABRICCANVANS[child.name] = new fabric.Canvas(child.name)
                    // window.FABRICCANVANS[child.name].backgroundColor = 'rgba(255,255,255,1)'
                    // fabric.Image.fromURL(`https://files.dizanz.com/2021-09-27/1632723468234.png?x-oss-process=image/resize,h_200,m_lfit`, (img) => {
                    //     window.FABRICCANVANS[child.name].add(img)
                    // },{ crossOrigin: 'anonymous' })
    
                    // let j = await fetch(`https://files.dizanz.com/2021-10-18/6855798882044116992.json`)
                    // j = await j.json()
                    // let jjon = j.find(v => {
                    //     return v.code === child.name
                    // })
                    // if(jjon){
                    //     console.log("jjon",jjon)
                    //     // jjon.vts = 
                    //     let points = []
                    //     for(let i=0;i<jjon.vts.length;i++){
                    //         points.push({x:jjon.vts[i][0]*(document.body.clientHeight / 2),y:jjon.vts[i][1]*(document.body.clientHeight / 2)})
                    //     }
                    //     console.log("jjon",points)
                    //     var polygon = new fabric.Polygon(points, {
                    //         fill: '#D81B60',
                    //         strokeWidth: 1,
                    //         stroke: 'green',
                    //         // objectCaching: false,
                    //         // transparentCorners: false,
                    //         // cornerColor: 'blue',
                    //     });
                    //     window.fcanvas.add(polygon)
                    // }
                    
                    //child.material.map = new THREE.CanvasTexture(document.getElementById(child.name))
                    //window.MAPS[child.name] = child.material.map
                }
            })
            onSuccess(objects)
        }, xhr => {
            let p = (xhr.loaded / xhr.total)*100;
            onProgress(parseInt(p))
        }, error => {
            onError(error)
        })

    //})
    

}



function loadSubObj(url){
    return new Promise((resolve, reject)=>{
        const subloader = new OBJLoader()
        subloader.load(url, (object) => {
            resolve(object.children[0])
        }, xhr => {
            // let p = (xhr.loaded / xhr.total)*100;
            // onProgress(parseInt(p))
        }, error => {
            reject(error)
        })
    })    
}

function setContent(object) {
    let camera = get_camera()
    object.updateMatrixWorld();
    const box = new THREE.Box3().setFromObject(object);
    // const size = box.getSize(new THREE.Vector3()).length();
    const boxSize = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());
    object.position.x = object.position.x - center.x;
    object.position.y = object.position.y - center.y;//修改center.y可以设置模型整体上下偏移
    object.position.z = object.position.z - center.z;
    camera.position.copy(center);
    if (boxSize.x > boxSize.y) {
        camera.position.z = boxSize.x * 2.85;
    } else {
        camera.position.z = boxSize.y * 2.85;
    }
    camera.lookAt(0, 0, 0);
}

//截图
function screenshots(order_id){
    const client = new oss({
        region: 'oss-cn-beijing',
        accessKeyId: process.env.REACT_APP_OSS_ID,
        accessKeySecret: process.env.REACT_APP_OSS_SECRET,
        bucket: 'jihe-file'
    });
    let camera = get_camera()
    let render = getRenderFrame()
    setContent(window.modelObject)
    camera.zoom = 1  
    camera.updateProjectionMatrix()
    setTimeout(()=>{
        render.domElement.toBlob(async (file)=>{
            try {
                let path = `order_cover/${order_id}.png`
                await client.put(path, file)
                // console.log(`https://files.dizanz.com/${path}`)
            } catch (e) {
                // message.error("上传失败")
            }
        }) 
    },500)
}

window.screenshots = screenshots

export {loadObj,loadSubObj,screenshots,setContent}