import * as THREE from 'three/build/three.module.js';

function scene(){
    if(window.scene){
        return window.scene
    }
    window.scene = new THREE.Scene()
    window.ambientLight = new THREE.AmbientLight( 0x443333 ) 
    window.scene.add( window.ambientLight );

    window.dirLight1 = new THREE.DirectionalLight( 0xffffff, 0.6 );
    window.dirLight1.position.set( 0, 0.75, 0.5 );
    window.scene.add( window.dirLight1 );

    window.dirLight2 = new THREE.DirectionalLight( 0xffffff, 0.6 );
    window.dirLight2.position.set( 0, 0.75, -0.5 );
    window.scene.add( window.dirLight2 );

    // window.dirLight3 = new THREE.DirectionalLight( 0xffffff, 0.5 );
    // window.dirLight3.position.set( 0, 0, -0.5 );
    // window.scene.add( window.dirLight3 );
    // load_hdr( texture => {
    //     window.scene.background = texture;
    //     window.scene.environment = texture;
    // })
    return window.scene
}

export default scene

