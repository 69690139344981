import React, { useState, useImperativeHandle } from 'react';
import styles from './index.module.css'

const menus = [
    {
        name:'选定为基准纹样',
        value:'base-pattern'
    },
    {
        name:'等比显示纹样',
        value:'ratio-pattern'
    },
    {
        name:'删除纹样',
        value:'delete-pattern'
    },
    {
        name:'四方连续',
        value:'repeat'
    },
    {
        name:'超级四方连续',
        value:'super-repeat'
    },
    {
        name:'二方连续X轴',
        value:'repeat-x'
    },
    {
        name:'二方连续Y轴',
        value:'repeat-y'
    },
    {
        name:'置于上一层',
        value:'up-layer'
    },
    {
        name:'置于顶层',
        value:'best-up-layer'
    }
]

const ContextMenu = (props) => {
    const [show,setShow] = useState(false)
    const [position,setPosition] = useState(false)
    useImperativeHandle(props.cRef, () => ({
        show: async (display) => {
            setShow(display)
        },
        hide: async (display) => {
            setShow(display)
        },
        setPosition: async (args) => {
            setPosition(args)
        }
    }));

    return (
        <div>
            <div className={styles.contextMenu} style={{left:position.left,top:position.top,display:show ? 'block':'none',width:100,backgroundColor:'rgb(51,51,51)',position:'absolute',zIndex:10}}>
                {
                    menus.map((item,dx)=>{
                        return <button onClick={()=>{props.onMenuClick(item)}} key={dx}>{item.name}</button>
                    })
                }
            </div>
        </div>
    )
}

export default ContextMenu