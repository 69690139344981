import { Spin } from 'antd'
import React from 'react'
import * as styles from './index.module.css'
import * as apis from '../../api'
import querystring from 'querystring'

class Index extends React.Component{
    state = {
        loading:false,
        designQrcode:{}
    }

    async loopScanResult(uuid){
       let scanResult = await apis.getDesignScanResult(uuid)
       if(scanResult.status === "ok"){
            window.location.href = "/index?" + querystring.stringify(scanResult)
            return
       }else if(scanResult.status === "waiting"){
            setTimeout(async ()=>{
                await this.loopScanResult(uuid)
            },1000)
       }else if(scanResult.status === "invalid"){
           window.location.reload()
       }
    }

    async componentDidMount(){
        document.body.style.background = 'rgb(24,24,24)'
        let designQrcode = await apis.getDesignQrcode()
        await this.loopScanResult(designQrcode.uuid)
        this.setState({designQrcode})
    }

    render(){
        let {loading,designQrcode} = this.state
        return (
            <Spin spinning={loading}>
                <div className={styles.loginBox}>
                    <img style={{width: 466}} src="https://files.dizanz.com/2021-07-09/1625832242607.jpg" alt="" />
                    <img style={{width: 150,margin: '0 auto'}} src={designQrcode.url} alt="" />
                    <p style={{color: '#666',textAlign: 'center',marginTop: 12}}>扫码开始设计</p> 
                </div>
                <p className={styles.foo}>北京芰荷科技</p> 
            </Spin>
        )
    }

}


export default Index