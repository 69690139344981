import React from 'react'
import styles from './index.module.css'
import * as THREE from 'three/build/three.module.js';
import { SketchPicker } from 'react-color';
import PatternList from './pattern_list'
import { Button, message,Modal } from 'antd'
import Loading from '../../common/loading'
import * as apis from '../../api'
import { loadObj, loadSubObj } from './load_obj'
import controls from './controls'
import {recursiveLoadCp,arrangement,allChoice, calculateStatusTxt, down_load_cp} from './cp'
import {onZoom,onMove,onMouseEnter,onMouseLeave,onMouseDown,onMouseUp,onCpMouseDown} from './zoom'
import {recursiveAddBypatterns,recursiveAdd,setBackgroudColor,deletePattern,movePattern,repeatPattern,ratioPattern,setBasePattern,clearAllPattern} from './pattern'
import {showFabic,getFabricCpCodes,setFabricCpCodes} from './fabric'
import ContextMenu from './context_menu'
import * as right_menu from './right_menu'
import querystring from 'querystring'
import oss from 'ali-oss'
import ConfirmOrder from './confirm_order'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { fabric } from "fabric";
import VConsole from 'vconsole';
const vConsole = new VConsole();



const client = new oss({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: 'oss-cn-beijing',
    // 从STS服务获取的临时访问凭证。临时访问凭证包括临时访问密钥（AccessKeyId和AccessKeySecret）和安全令牌（SecurityToken）。
    accessKeyId: process.env.REACT_APP_OSS_ID,
    accessKeySecret: process.env.REACT_APP_OSS_SECRET,
    // 填写Bucket名称。
    bucket: 'jihe-file'
});

const addedCode = {}

 class Index extends React.Component{
    state = {
        need_detail:{fashion:{fashion:{},base_cutpart_list:[]},detail:{patterns:[],fabrics:[]}},
        calculatedCutparts: [],
        calculated: false,
        articleList:[],
        loading:false,
        searchName:"",
        currentChoiceColor:"#FFFFFF",
        slideType:0,
        fabricList:[],
        tempRightData:[],
        layoutDetailData:{desFashionList:[],desCpLists:[],bodyData:{},fcpInfo:[{sucCount:0,total:0}]},
        allChoiceFlag:false,
        visibleMode:false,
        fabricCpcodes:[],
        contextMenuItem:{},
        layoutId:undefined,
        uid:undefined,
        clientUid:0, //客户的uid
        backdomain:"",
        from:'',
        showCutPartName: true,
        rightMenuSecondActive:'',
        beforCutNum: 0,
        fashion_detail: null,
        cutpart_list: [{"code":"houbei","txt":"后背"},{"code":"lingzi","txt":"领子"},{"code":"qianxiong","txt":"前胸"},{"code":"youxiu","txt":"右袖"},{"code":"zuoxiu","txt":"左袖"}],
        function_list: [{"disabled": false,"txt":"图案"},{"disabled": false, "txt":"颜色"},{"disabled": false, "txt":"自由创作"},{"disabled": false, "txt":"文字"},{"disabled": false, "txt":"前进"},{"disabled": false, "txt":"撤销"}],
        pattern_menus: [{"disabled": false,"txt":"碎花"},{"disabled": false, "txt":"删除"},{"disabled": false, "txt":"风格化"},{"disabled": false, "txt":"前进"},{"disabled": false, "txt":"撤销"},{"disabled": false, "txt":"完成"}],
        global_color: "#FFF",
        current_cutpart_index: 0,
        free_edit: false,
        pattern_edit_menu: false
    }

    patternListRef = React.createRef()
    loadingRef = React.createRef()
    objectRef = React.createRef()
    contextMenuRef = React.createRef()
    confirmRef = React.createRef()
    swiper = null
    edit_canvas = null

    async componentDidMount(){
        controls()        
        document.body.style.background = "rgba(56, 57, 58, 1)" 
        // const urlArgs = new URLSearchParams(window.location.href.split("?")[1])
        // let fashion_id = urlArgs.get("fashion_id")
        // try {
        //     var fashion_detail = await apis.get_fashion_detail(fashion_id)
        //     var cutpart_list = await apis.get_fashion_cutpart(fashion_id)
        //     setTimeout(()=>{
        setTimeout(()=>{
            this.swiper = new window.Swiper('.swiper-container',{
                //freeMode : false,
                slidesPerView: 'auto'
                //freeModeMomentum : false
            })
        },500)
        // const { cutpart_list } = this.state
        // let await_all = []
        // for(let i=0;i<cutpart_list.length;i++){
        //     let item = cutpart_list[i]
        //     await_all.push(loadSubObj(`./test_objs/${item.code}.obj`))
        // }

        // let objects = await Promise.all(await_all)
        // console.log(objects)
        // var group = new THREE.Group();
        // for(let i=0;i<objects.length;i++){
        //     group.add(objects[i])
        // }
        // window.scene.add(group)
        setTimeout(()=>{
            this.loadingRef.current.display(true)
            this.loadingRef.current.do(0)
            loadObj(`./test_objs/test.obj`, async (objects)=>{
                // complete(objects)        
                this.loadingRef.current.display(false)

                const { cutpart_list } = this.state
                setTimeout(()=>{
                    for(let i=0;i<cutpart_list.length;i++){
                        let item = cutpart_list[i]
                        let c = window.FABRICCANVANS[item.code]
                        c.on('object:moving',(e) => {
                            //MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
                            //this.canvas_data_clone(code)
                            window.MAPS[item.code].needsUpdate = true
                        })
                        c.on('object:scaling',(e) => {
                            //MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
                            //this.canvas_data_clone(code)
                            window.MAPS[item.code].needsUpdate = true
                        })
                        c.on('object:rotating',(e) => {
                            //MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
                            //this.canvas_data_clone(code)
                            window.MAPS[item.code].needsUpdate = true
                        })
                        c.on('object:moved',(e) => {
                            //MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
                            //this.canvas_data_clone(code)
                            window.MAPS[item.code].needsUpdate = true
                        })
                        c.on('after:render',(e) => {
                            //MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
                            // console.log(this.edit_canvas.getActiveObjects())
                            setTimeout(()=>{
                                window.MAPS[item.code].needsUpdate = true
                            },100)
                            this.setState({pattern_edit_menu: c.getActiveObjects().length !== 0})
                        })
                        c.on('path:created',(e) => {
                            //MAPS[e.target.canvas.contextContainer.canvas.id].needsUpdate = true
                            // console.log(this.edit_canvas.getActiveObjects())
                            //this.setState({pattern_edit_menu: this.edit_canvas.getActiveObjects().length !== 0})
                            // console.log(e.path)
                            this.edit_canvas.setActiveObject(e.path)
                            this.edit_canvas.isDrawingMode = false
                            setTimeout(()=>{
                                window.MAPS[item.code].needsUpdate = true
                            },100)
                        })
                    }
                },1000)
            
                
                

            },progress => {
                this.loadingRef.current.do(progress)
            },err => {
                console.log("加载错误",err)
            })
        },1500)
        //     fashion_detail.obj_url = fashion_detail.obj_url.replace("jihe-file.oss-cn-beijing-internal.aliyuncs","files.dizanz")
        //     this.setState({fashion_detail,cutpart_list})
        //     window.hideLoading()
        // } catch (error) {
        //     window.hideLoading()
        //     return
        // }  
        
        
        // return
        // if(urlArgs.from){
        //     this.setState({from:urlArgs.from})
        // }
        // window.hideLoading()
        // let wh = document.body.clientHeight
        // document.getElementById("leftBox").style.height = `${wh-38}px`
        // document.getElementById("rightBox").style.height = `${wh-38}px`
        // document.getElementById("middleBox").style.height = `${wh-38}px`
        // document.getElementById("middle3DContent").style.height = `${wh-100}px`
        // document.getElementById("middle2DContent").style.height = `${wh-100}px`

        //3Dcanvas自适应
        // canvasRenderResize(canvas)
        // window.renderer.setSize( document.getElementById("middle3DContent").clientWidth, document.getElementById("middle3DContent").clientHeight );
        // window.camera.aspect = document.getElementById("middle3DContent").clientWidth / document.getElementById("middle3DContent").clientHeight
        // window.camera.updateProjectionMatrix()
        // this.objectRef.current.contentDocument.defaultView.addEventListener("resize", () => {
        //     window.renderer.setSize( document.getElementById("middle3DContent").clientWidth, document.getElementById("middle3DContent").clientHeight );
        //     window.camera.aspect = document.getElementById("middle3DContent").clientWidth / document.getElementById("middle3DContent").clientHeight
        //     window.camera.updateProjectionMatrix()
        // });

        // document.addEventListener('window.onresize',()=>{
        //     let wh = document.body.clientHeight
        //     document.getElementById("leftBox").style.height = `${wh-38}px`
        //     document.getElementById("rightBox").style.height = `${wh-38}px`
        //     document.getElementById("middleBox").style.height = `${wh-38}px`
        //     document.getElementById("middle3DContent").style.height = `${wh-100}px`
        //     document.getElementById("middle2DContent").style.height = `${wh-100}px`
        // })
        
        // window.$(document).click(()=>{
        //     this.contextMenuRef.current.show(false)
        // })

        //let need_detail = await apis.getNeedDetail(urlArgs.need_id)
        // //获取裁片的背景颜色
        // try {
        //     let ret = await fetch(`https://files.dizanz.com/backgrounds/${urlArgs.need_id}.json?${new Date().getTime()}`)
        //     if(ret.status === 200){
        //         var retJson = await ret.json()
        //     }
        // } catch (err) {
            
        // }
        // for (let i=0;i<need_detail.fashion.base_cutpart_list.length;i++){
        //     let item = need_detail.fashion.base_cutpart_list[i]
        //     if(retJson){
        //         item.background_color = retJson[item.code]
        //     }
        //     window.CODECPS[item.code] = item
        // }
        // this.setState({need_detail},async ()=>{
        //     this.slideFabric(1)
        //     window.hideLoading()
            //加载3D模型
            //this.loadLayout(need_detail,()=>{
                //渲染每个部位空的画布
                //recursiveLoadCp(need_detail.fashion.base_cutpart_list)
                //动态渲染算好的裁片
                // setTimeout(async ()=>{
                //     await this.loadCutParts(need_detail.detail.id,need_detail)
                // },300)
            //})
        // },500)
    }

    async loadPatterns(need_detail){
        this.loadingRef.current.display(true)
        try {
            let resp = await fetch(`https://files.dizanz.com/patterns/${need_detail.detail.id}.json?${new Date().getTime()}`)
            if(resp.status === 200){
                resp = await resp.json()
                recursiveAddBypatterns(resp,(progress)=>{
                    this.loadingRef.current.do(progress)
                },()=>{
                    this.loadingRef.current.display(false)
                })
            }
        } catch (error) {
            console.log(error)
            this.loadingRef.current.display(false)
        }
    }

    async loadCutParts(needId,need_detail){
        if(this.state.calculated){
            return
        }
        let calculatedCutparts = await apis.getCutpartsByNeedId(need_detail.detail.id)
        if(!calculatedCutparts){
            setTimeout(async () => {
                await this.loadCutParts(needId, need_detail)
            },2000)
            return
        }
        
        let cutpartsStatus = await apis.getCutpartsCalculateStatusByNeedId(need_detail.detail.id)
        let newCutparts = []
        let statusIds = []
        for (let i=0;i<need_detail.fashion.base_cutpart_list.length;i++){
            let item = need_detail.fashion.base_cutpart_list[i]
            let finded = cutpartsStatus.find(v => {
                return v.cutpart_id === item.id
            })
            if(finded){
                statusIds.push(finded.id)
                item.status = finded.status
            }
            let finded2 = calculatedCutparts.find(v => {
                return v.cutpart_id === item.id
            })
            if(finded2){
                item = Object.assign(finded2, item)
                item.ratio = parseFloat(item.ratio)
                window.CODECPS[item.code] = item //更新全局数据
            }
            newCutparts.push(item)
            //为裁片添加底图
            if(item.img_line && !addedCode[item.code]){
                addedCode[item.code] = 1
                loadSubObj(item.code,item.img_line,item.after_obj)
            }
        }
        need_detail.fashion.base_cutpart_list = newCutparts
        
        if(statusIds.length !== 0){
            let minId = statusIds.sort()[0]
            let minIdResult = await apis.getLineupNum(minId)
            this.setState({beforCutNum:minIdResult.num || 0})
        }
        
        let calculated = false
        let breake = false
        for (let i=0;i<newCutparts.length;i++){
            let item = newCutparts[i]
            if(!item.status || item.status === 1 || item.status === 2){ //有裁片未计算完成
                breake = true
                break
            }
        }

        if(!breake){
            this.setState({calculated:true})
        }

        if(!calculated){
            setTimeout(async () => {
                await this.loadCutParts(needId, need_detail)
            },2000)
        }
        this.setState({calculatedCutparts,need_detail}) 
    }

    loadLayout(needDetail,complete){ 
        this.loadingRef.current.display(true)
        this.loadingRef.current.do(0)
        loadObj(`https://files.dizanz.com/2021-06-23/1122.obj?1235565`, async (objects)=>{
            // scene = getScene()
            // camera = getCamera(canvas)
            // renderFrame = getRenderFrame(canvas)
            // controls = getControls(camera,renderFrame)
            // scene.add(camera)
            // scene.add(objects)
            // this.loopRender(renderFrame,scene,camera)
            complete(objects)
            // let {scale,translate,transformOrigin} = onZoom({})
            // if(!scale){
            //     return
            // }
            // this.loadCutParts(needDetail)
            //window.$("#middle2DContentZoom").css({transform:`scale(${scale}) translate(${translate[0]}px, ${translate[1]}px)`,'transform-origin': `${transformOrigin[0]}px ${transformOrigin[1]}px`})
            
            this.loadingRef.current.display(false)
            // //加载纹样
            // this.loadPatterns(needDetail)
            // //加载布料
            // this.loadFabrics()


        },progress => {
            this.loadingRef.current.do(progress)
        },err => {
            console.log("加载错误",err)
        })
    }

    async loadFabrics(){
        let { need_detail } = this.state
        try {
            let ret = await fetch(`https://files.dizanz.com/fabrics/${need_detail.detail.id}.json?${new Date().getTime()}`)
            if(ret.status === 200){
                let retJson = await ret.json()
                for(let i=0;i<retJson.length;i++){
                    let finded = need_detail.fashion.base_cutpart_list.find(v => {
                        return v.code === retJson[i].code
                    })
                    if(finded){
                        setFabricCpCodes(retJson[i])
                    }
                }
                //随便setState一个值，只是为了重新render
                this.setState({update:true})
            }
        } catch (error) {
            
        }
    }

    async doSaveLayout(){ 
        let { need_detail } = this.state
        let fabricCpCodes = getFabricCpCodes()  
        window.showLoading()
        const patterns = [];
        const imgBases = {};//每个裁片和纹样组合的base64
        const backgrounds = {}
        for (let i=0;i<need_detail.fashion.base_cutpart_list.length;i++) {
            let item = need_detail.fashion.base_cutpart_list[i]
            imgBases[item.code] = window.FABRICCANVANS[item.code].toDataURL({format: 'jpeg',quality:1})
            backgrounds[item.code] = window.FABRICCANVANS[item.code].backgroundColor
            let objects = window.FABRICCANVANS[item.code].getObjects()
            for(let j=0;j<objects.length;j++){
                let val = objects[j]
                patterns.push({
                    sizeHeight: 113,
                    sizeWidth: 113,
                    positionWidth: val.left - 300,
                    positionHeight: val.top - 300,
                    angle: val.angle * (Math.PI/180),
                    scaleWidth: val.getScaledWidth()/113,
                    scaleHeight: val.getScaledHeight()/113,
                    imgUrl: val.getSrc().split("?")[0],
                    patternId:val.patternId,
                    code: item.code,
                    zlevel:j
                })
            }
        }
        let patternInfo = client.put(`patterns/${need_detail.detail.id}.json`, new oss.Buffer(JSON.stringify(patterns)));
        let texturesInfo = client.put(`textures/${need_detail.detail.id}.json`, new oss.Buffer(JSON.stringify(imgBases)));
        let fabricsInfo = client.put(`fabrics/${need_detail.detail.id}.json`, new oss.Buffer(JSON.stringify(fabricCpCodes)));
        let backgroundsInfo = client.put(`backgrounds/${need_detail.detail.id}.json`, new oss.Buffer(JSON.stringify(backgrounds)));

        try {
            await Promise.all([patternInfo,texturesInfo,fabricsInfo,backgroundsInfo])
            window.hideLoading()
        } catch (error) {
            window.hideLoading()
            return false
        }
        return true    
    }

    async slideFabric(stp){
        let {slideType} = this.state
        if(slideType === stp){
            return
        }
        this.rightMenuSecondSlide({item:{value:stp},v:{value:1}})
        this.setState({slideType:stp,rightMenuSecondActive:`${stp}_1`})
    }

    //右侧二级菜单切换
    async rightMenuSecondSlide({item,v}){
        const { need_detail } = this.state
        this.setState({rightMenuSecondActive:`${item.value}_${v.value}`})
        if(`2_2` === `${item.value}_${v.value}`){
            window.showLoading()
            let cloudPatternList = await apis.getPatternList({page:1,page_size:20,search:{}})
            this.setState({tempRightData:cloudPatternList.base_pattern_list})
            window.hideLoading()
        }else if(`2_3` === `${item.value}_${v.value}`){
            let cloudPatternList = await apis.getDiyPatternList()
            this.setState({tempRightData:cloudPatternList.list})
        }else if(`2_1` === `${item.value}_${v.value}`){
            this.setState({tempRightData:need_detail.detail.patterns})
        }else if(`1_1` === `${item.value}_${v.value}`){
            this.setState({tempRightData:need_detail.detail.fabrics})
        }else if(`1_2` === `${item.value}_${v.value}`){
            window.showLoading()
            let fashionFabrics = await apis.getFashionFabric(need_detail.detail.fashion_id)
            this.setState({tempRightData:fashionFabrics.list})
            window.hideLoading()
        }
    }

    //右键菜单
    cpBoxOnContextMenu(item,e){
        if(!window.CHOICESPATTERNSINFO[item.code]){
            return   
        }
        let left = (e.pageX - window.$("#middle2DContent").offset().left)
        let top = (e.pageY - window.$("#middle2DContent").offset().top) + 70
        this.contextMenuRef.current.show(true)
        this.contextMenuRef.current.setPosition({left,top})
        this.setState({contextMenuItem:item})
    }

    //生成订单弹窗
    async readyMakeOrder(){
        let { need_detail } = this.state
        let fabricCpCodes = getFabricCpCodes()
        if(fabricCpCodes.length !== need_detail.fashion.base_cutpart_list.length){
            message.error('请为裁片选择面料')
            return false
        }
        //先保存一下草稿
        let saveResult = await this.doSaveLayout()
        if(!saveResult){
            return
        }
        this.confirmRef.current.do(need_detail)
    }

    canvas_data_clone(code){
        window.FABRICCANVANS[code].loadFromJSON(this.edit_canvas.toJSON())
    }

    render(){
        let {pattern_menus, cutpart_list, global_color, current_cutpart_index, function_list, free_edit, pattern_edit_menu } = this.state
        return (
           <>
            <PatternList cRef={this.patternListRef} onChange={ img_src => {
                fabric.Image.fromURL(img_src, (img) => {
                    let code = cutpart_list[current_cutpart_index].code
                    img.scaleToWidth(200)
                    this.edit_canvas = window.FABRICCANVANS[code]
                    this.edit_canvas.add(img)
                    setTimeout(()=>{
                        window.MAPS[code].needsUpdate = true
                        this.edit_canvas.setActiveObject(img)
                        this.edit_canvas.viewportCenterObject(img)
                        this.edit_canvas.renderAll()
                    },100)
                    //window.FABRICCANVANS[cutpart_list[current_cutpart_index].code].add(img)
                },{ crossOrigin: 'anonymous' })
            }} />
            <ConfirmOrder cRef={this.confirmRef} />
            <Loading cRef={this.loadingRef} />
            <input id="color_selector" type="color" style={{display:'none'}} onChange={ e => {
                for(let code in window.FABRICCANVANS){
                    window.FABRICCANVANS[code].backgroundColor = e.target.value
                    window.FABRICCANVANS[code].renderAll()
                }
                this.edit_canvas.backgroundColor = e.target.value
                this.edit_canvas.renderAll()
                this.setState({global_color: e.target.value})
            }} />
            <div style={{position:'relative'}}>
                <canvas id="canvas" style={{display:'block'}}></canvas>
                <div style={{position:'absolute',top: 0,left: 0,display:'flex'}}>
                    <button onClick={()=>{
                        let random_color = `rgb(${Math.random()*255},${Math.random()*255},${Math.random()*255})`
                        for(let code in window.FABRICCANVANS){
                            window.FABRICCANVANS[code].backgroundColor = random_color
                            window.FABRICCANVANS[code].renderAll()
                        }
                        // console.log(112233)
                        // document.getElementById("color_selector").click()
                    }}>颜色</button>
                    <button onClick={()=>{
                        
                    }}>随机穿搭</button>
                    <button style={{display: free_edit ? 'none':'block'}} onClick={()=>{
                        window.renderer.setSize( document.body.clientWidth, document.body.clientHeight - 400);
                        window.camera.aspect = document.body.clientWidth / (document.body.clientHeight - 400)
                        window.camera.updateProjectionMatrix()
                        //this.edit_canvas.loadFromJSON(window.FABRICCANVANS[cutpart_list[current_cutpart_index].code].toJSON())
                        this.setState({free_edit: true })
                    }}>自由创作</button>
                </div>
                
                <button style={{position:'absolute',bottom: 12,right: 12, display: free_edit ? 'block':'none'}} onClick={()=>{
                    window.renderer.setSize( document.body.clientWidth, document.body.clientHeight);
                    window.camera.aspect = document.body.clientWidth / (document.body.clientHeight)
                    window.camera.updateProjectionMatrix()
                    this.setState({free_edit: false})
                }}>全屏</button>
                <button style={{position:'absolute',right: 12, top: 12,background:'red',color:'#fff'}} onClick={()=>{
                    let dataURL = window.FABRICCANVANS['qianpian'].toDataURL({
                        width: 300,
                        height: 300,
                        format:'png',
                        enableRetinaScaling: true
                    })
                    console.log(dataURL)
                }}>保存</button>
            </div>
            
            <div style={{display: free_edit ? 'block':'none'}}>
                <div style={{width: '100%',height: 50, textAlign: 'center',display: 'flex',justifyContent: 'center', background: '#fff',position:'relative'}}>
                    <div className="swiper-container" style={{width:'100%',height:'100%',overflow:'hidden'}}>
                        <div className="swiper-wrapper">
                            {
                                cutpart_list.map((v,i) => (
                                    <div key={v.code} onClick={() => {
                                        // console.log(v,i)
                                        // console.log(window.FABRICCANVANS[v.code].toJSON())
                                        for(let code in window.FABRICCANVANS){
                                            window.FABRICCANVANS[code].discardActiveObject()
                                            window.FABRICCANVANS[code].renderAll()
                                        }
                                        this.edit_canvas = window.FABRICCANVANS[v.code]
                                        console.log(this.edit_canvas)
                                        this.setState({current_cutpart_index: i})
                                    }} className="swiper-slide" style={{display:'inline-block',width:'auto',padding: 13,background:current_cutpart_index === i ? '#ccc':'#fff'}}>{v.txt}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div style={{height: 300}}>
                    {
                        cutpart_list.map((v,i) => (
                            <div style={{display: current_cutpart_index === i ? 'flex':'none', justifyContent: 'center',position:'relative'}}>
                                <div className={styles.cp_image_view} style={{backgroundImage: `url(./test_objs/${v.code}.png)`}}></div>
                                <canvas key={v.code} id={v.code} className={styles.canvas2D} width={300} height={300}></canvas>
                            </div>
                        ))
                    }
                </div>
                <div style={{display: pattern_edit_menu ? 'none':'flex',width: '100%',height: 50, textAlign: 'center',justifyContent: 'center', background: '#fff',position:'relative'}}>
                    <div className="swiper-container" style={{width:'100%',height:'100%',overflow:'hidden'}}>
                        <div className="swiper-wrapper">
                            {
                                function_list.map((v,i) => (
                                    <div key={i} onClick={() => {
                                        console.log(v,i)
                                        if(i === 0){
                                            this.patternListRef.current.do()
                                        }
                                        if(i === 1){
                                            let random_color = `rgb(${Math.random()*255},${Math.random()*255},${Math.random()*255})`
                                            this.edit_canvas.backgroundColor = random_color
                                            this.edit_canvas.renderAll()
                                        }
                                        if(i === 2){
                                            if(this.edit_canvas.isDrawingMode){
                                                this.edit_canvas.isDrawingMode = false
                                                return
                                            }
                                            this.edit_canvas.freeDrawingBrush = new fabric.SprayBrush(this.edit_canvas);
                                            this.edit_canvas.freeDrawingBrush.width = 10;
                                            this.edit_canvas.isDrawingMode = true;
                                        }
                                        if(i === 3){
                                            let textbox = new fabric.Textbox('Hello', {
                                                // left: 0,
                                                // top: 0,
                                                width: 150,
                                                fontSize: 20
                                            });
                                            this.edit_canvas.add(textbox).viewportCenterObject(textbox).setActiveObject(textbox);
                                        }
                                        // console.log(window.FABRICCANVANS[v.code].toJSON())
                                        // this.edit_canvas.loadFromJSON(window.FABRICCANVANS[v.code].toJSON())
                                        // this.setState({current_cutpart_index: i})
                                    }} className="swiper-slide" style={{display:'inline-block',width:'auto',padding: 13}}>{v.txt}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div style={{display: pattern_edit_menu ? 'flex':'none',width: '100%',height: 50, textAlign: 'center',justifyContent: 'center', background: '#fff',position:'relative'}}>
                    <div className="swiper-container" style={{width:'100%',height:'100%',overflow:'hidden'}}>
                        <div className="swiper-wrapper">
                            {
                                pattern_menus.map((v,i) => (
                                    <div key={i} onClick={() => {
                                        console.log(v,i)
                                        if(i === 0){
                                            // this.patternListRef.current.do()
                                        }
                                        if(i === 1){
                                            this.edit_canvas.remove(this.edit_canvas.getActiveObjects()[0])
                                            this.edit_canvas.renderAll()
                                        }
                                        if(i === 3){
                                            this.edit_canvas.discardActiveObject()
                                            this.edit_canvas.renderAll()
                                            
                                        }
                                        if(i === 5){
                                            this.edit_canvas.discardActiveObject()
                                            this.edit_canvas.renderAll()
                                        }
                                        // console.log(window.FABRICCANVANS[v.code].toJSON())
                                        // this.edit_canvas.loadFromJSON(window.FABRICCANVANS[v.code].toJSON())
                                        // this.setState({current_cutpart_index: i})
                                    }} className="swiper-slide" style={{display:'inline-block',width:'auto',padding: 13}}>{v.txt}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
           </>
        )
    }

 }

export default Index