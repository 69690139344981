import { fabric } from "fabric";
const {FABRICCANVANS,CHOICESPATTERNSINFO,FABRICCANVANSANDPATTERNS,REPEATPATTERNS} = window
function addPattern(code,src,options = {
    // left:FABRICCANVANS[code].getWidth()/2,
    // top:FABRICCANVANS[code].getHeight()/2,
    // scaleWidth:113,
    // patternId:new Date().getTime().toString()
},onSuccess){
    fabric.Image.fromURL(src, (oImg) => {
        oImg.set('patternId',options.patternId || new Date().getTime().toString())
        oImg.originX = 'center'
        oImg.originY = 'center'
        oImg.src = src
        // this.zIndexMap[this.editCpCode]++
        // oImg.set('parent_code',this.editCpCode)
        // oImg.set('zindex',this.zIndexMap[this.editCpCode])
        oImg.setControlVisible("ml", false)
        oImg.setControlVisible("mt", false)
        oImg.setControlVisible("mr", false)
        oImg.setControlVisible("mb", false)
        oImg.left = options.left || FABRICCANVANS[code].getWidth()/2
        oImg.top = options.top || FABRICCANVANS[code].getHeight()/2
        oImg.scaleToWidth(options.scaleWidth || 113)
        if(options.angle){
            oImg.angle = options.angle/(Math.PI/180)
        }
        
        oImg.on('selected',(e) => {
            window.CURRENTCODE = code
            window.CURRENTPATTERN = oImg
            CHOICESPATTERNSINFO[code] = oImg
            // setTimeout(()=>{
            //     MAPS[code].needsUpdate = true
            // },100)
        })
        oImg.on('deselected',(e) => {
            // window.CURRENTPATTERN = undefined
            // window.CURRENTCODE = undefined
            delete CHOICESPATTERNSINFO[code]
            // setTimeout(()=>{
            //     MAPS[code].needsUpdate = true
            // },100)
        })
        if(!FABRICCANVANSANDPATTERNS[code]){
            FABRICCANVANSANDPATTERNS[code] = []
            FABRICCANVANSANDPATTERNS[code].push(oImg)
        }else{
            FABRICCANVANSANDPATTERNS[code].push(oImg)
        }
        FABRICCANVANS[code].add(oImg)
        setTimeout(()=>{
            //MAPS[code].needsUpdate = true
            onSuccess(oImg)
        },100)
    },{ crossOrigin: '' })
}

let addIndex = 0

function recursiveAdd(src,onProgress,onEnd,onError){
    if(window.cpChoiced.length === 0){
        onError('请选择裁片')
        return
    }
    if(window.cpChoiced.length === addIndex){
        addIndex = 0
        onEnd()
        return
    }
    let code = window.cpChoiced[addIndex]
    addPattern(code,src,{},()=>{
        onProgress((addIndex+1)/window.cpChoiced.length*100)
        addIndex++
        recursiveAdd(src,onProgress,onEnd,onError)
    })
}

function setBackgroudColor(color){
    for(let i=0;i<window.cpChoiced.length;i++){
        let c =  FABRICCANVANS[window.cpChoiced[i]]
        // let code = window.cpChoiced[i]
        c.backgroundColor = color
        c.renderAll()
        //MAPS[code].needsUpdate = true
    }
}

function deletePattern(code){
    if(!CHOICESPATTERNSINFO[code]){
        return false
    }
    let objects = FABRICCANVANS[code].getObjects()
    for(let i=0;i<objects.length;i++){
        if(objects[i].patternId === CHOICESPATTERNSINFO[code].patternId){
            FABRICCANVANS[code].fxRemove(objects[i])
            delete REPEATPATTERNS[objects[i].patternId]
        }
    }
    
    FABRICCANVANS[code].renderAll()
    delete CHOICESPATTERNSINFO[code]
    //MAPS[code].needsUpdate = true
    return true
}

function movePattern(code,value){
    if(!CHOICESPATTERNSINFO[code]){
        return [false,'请选择一个纹样']
    }
    if(REPEATPATTERNS[CHOICESPATTERNSINFO[code].patternId]){
        return [false,'此纹样不支持此操作']
    }
    if (value === 'best-up-layer'){
        FABRICCANVANS[code].bringToFront(CHOICESPATTERNSINFO[code])
    }
    if (value === 'up-layer'){
        FABRICCANVANS[code].bringForward(CHOICESPATTERNSINFO[code],true)
    }
    if (value === 'down-layer'){
        FABRICCANVANS[code].bringForward(CHOICESPATTERNSINFO[code])
    }
    FABRICCANVANS[code].renderAll()
    //MAPS[code].needsUpdate = true
    return [true,'']
}

function ratioPattern(code){
    if(!CHOICESPATTERNSINFO[code]){
        return [false,'请选择一个纹样']
    }
    if(REPEATPATTERNS[CHOICESPATTERNSINFO[code].patternId]){
        return [false,'此纹样不支持此操作']
    }
    let editPattern = CHOICESPATTERNSINFO[code]
    editPattern.scaleToWidth((window.BasePattern[1] * window.CODECPS[code].ratio)/window.BasePattern[0])
    FABRICCANVANS[code].renderAll()
    //MAPS[code].needsUpdate = true
    return [true,'']
}

function repeatPattern(code,value){
    if(!CHOICESPATTERNSINFO[code]){
        return [false,'请选择一个纹样']
    }
    if(REPEATPATTERNS[CHOICESPATTERNSINFO[code].patternId]){
        return [false,'此纹样不支持此操作']
    }
    if(value === 'repeat'){
        let baseObj = CHOICESPATTERNSINFO[code]
        let xNum = Math.ceil(600/(baseObj.width*baseObj.scaleX))+1
        let jindex = 0
        for(let j=0;j<xNum;j++){
            jindex++
            let index = 0
            for(let i=0;i<xNum;i++){
                let clone = fabric.util.object.clone(baseObj);
                REPEATPATTERNS[baseObj.patternId] = 'clone'
                index++
                clone.set({left: (index+i)*baseObj.width/2*baseObj.scaleX,top: (jindex+j)*baseObj.width/2*baseObj.scaleX});
                FABRICCANVANS[code].add(clone);
            }
        }
        FABRICCANVANS[code].fxRemove(baseObj)
    }

    if(value === 'repeat-x'){
        let baseObj = CHOICESPATTERNSINFO[code]
        let xNum = Math.ceil(600/(baseObj.width*baseObj.scaleX))+1
        let index = 0
        for(let i=0;i<xNum;i++){
            let clone = fabric.util.object.clone(baseObj);
            REPEATPATTERNS[baseObj.patternId] = 'clone'
            index++
            clone.set({left: (index+i)*baseObj.width/2*baseObj.scaleX,top: baseObj.top});
            FABRICCANVANS[code].add(clone);
        }
        FABRICCANVANS[code].fxRemove(baseObj)
    }

    if(value === 'repeat-y'){
        let baseObj = CHOICESPATTERNSINFO[code]
        let xNum = Math.ceil(600/(baseObj.width*baseObj.scaleX))+1
        let index = 0
        for(let i=0;i<xNum;i++){
            let clone = fabric.util.object.clone(baseObj);
            REPEATPATTERNS[baseObj.patternId] = 'clone'
            index++
            clone.set({left: baseObj.left,top: (index+i)*baseObj.width/2*baseObj.scaleX});
            FABRICCANVANS[code].add(clone);
        }
        FABRICCANVANS[code].fxRemove(baseObj)
    }

    if(value === 'super-repeat'){
        if(window.cpChoiced.length === 0){
            return [false,'请选择裁片']
        }
        let baseObj = CHOICESPATTERNSINFO[code]
    
        for(let i=0;i<window.cpChoiced.length;i++){
            let cd = window.cpChoiced[i]
            addPattern(cd,baseObj.src,{
                angle:baseObj.angle,
                scaleWidth:(baseObj.getScaledWidth() * window.CODECPS[cd].ratio)/window.CODECPS[code].ratio
            },(newPattern)=>{
                let xNum = Math.ceil(600/(newPattern.width*newPattern.scaleX))+1
                let jindex = 0
                for(let j=0;j<xNum;j++){
                    jindex++
                    let index = 0
                    for(let i=0;i<xNum;i++){
                        let clone = fabric.util.object.clone(newPattern);
                        REPEATPATTERNS[newPattern.patternId] = 'clone'
                        index++
                        clone.set({left: (index+i)*newPattern.width/2*newPattern.scaleX,top: (jindex+j)*newPattern.width/2*newPattern.scaleX});
                        FABRICCANVANS[cd].add(clone);
                    }
                }
                FABRICCANVANS[cd].fxRemove(newPattern)
                // setTimeout(()=>{
                //     MAPS[cd].needsUpdate = true
                // },100)                
            })
        }
        FABRICCANVANS[code].fxRemove(baseObj)
    }
    
    FABRICCANVANS[code].renderAll();
    return [true,'']
}

function setBasePattern(code){
    if(!CHOICESPATTERNSINFO[code]){
        return [false,'请选择一个纹样']
    }
    if(REPEATPATTERNS[CHOICESPATTERNSINFO[code].patternId]){
        return [false,'此纹样不支持此操作']
    }
    let editPattern = CHOICESPATTERNSINFO[code]
    window.BasePattern = [window.CODECPS[code].ratio,editPattern.getScaledWidth()]
    return [true,'']
}
let mapCode = {}
window.recursiveAddBypatternsIndex = 0
let imgs = {}
function recursiveAddBypatterns(patterns,onProgress,onEnd){
    if(patterns.length === 0){
        return
    }
    for (let i=0;i<patterns.length;i++){
        let item = patterns[i]
        let top
        if(item.positionHeight < 0){
            top = 300 - Math.abs(item.positionHeight)
        }else{
            top = 300 + item.positionHeight
        }
         //选出是repeat的纹样
        if(!mapCode[item.code]){
            mapCode[item.code] = 1
        }else{
            window.REPEATPATTERNS[item.patternId.toString()] = 'clone'
        }
        addPattern(item.code,item.imgUrl + "?x-oss-process=image/resize,h_600",{
            left:item.positionWidth + 300,
            top:top,
            scaleWidth:item.sizeWidth * item.scaleWidth,
            patternId:item.patternId.toString(),
            angle:item.angle
        },(img)=>{
            imgs[item.code + "_" + item.zlevel] = img
            window.recursiveAddBypatternsIndex++
            if(window.recursiveAddBypatternsIndex === patterns.length){
                onEnd()
                resetZindex(imgs)
            }
            onProgress((window.recursiveAddBypatternsIndex+1)/patterns.length*100)
            // recursiveAddBypatterns(patterns,onProgress,onEnd)
        })
    }
    
    
}

function clearAllPattern(){
    if(window.cpChoiced.length === 0){
        return
    }
    for(let i=0;i<window.cpChoiced.length;i++){
        let objs = FABRICCANVANS[window.cpChoiced[i]].getObjects()
        FABRICCANVANS[window.cpChoiced[i]].remove(...objs)
        // setTimeout(()=>{
        //     MAPS[window.cpChoiced[i]].needsUpdate = true
        // },100)
    }
}

function resetZindex(ms){
    for(let i in ms){
        ms[i].moveTo(parseInt(i.split("_")[1]))
        // setTimeout(()=>{
        //     if(MAPS[i.split("_")[0]]){
        //         MAPS[i.split("_")[0]].needsUpdate = true
        //     } 
        // },100) 
    }
}

export {addPattern,recursiveAdd,setBackgroudColor,deletePattern,movePattern,repeatPattern,ratioPattern,setBasePattern,recursiveAddBypatterns,clearAllPattern}