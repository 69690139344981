import * as THREE from 'three/build/three.module.js';
function getRenderFrame(){
    if(window.renderer){
        return window.renderer
    }
    window.renderer = new THREE.WebGLRenderer({
        canvas: document.getElementById("canvas"),
        preserveDrawingBuffer: true,
        antialias: true,
        autoClear: true,
        alpha: true
    });
    window.renderer.outputEncoding = THREE.sRGBEncoding;
    window.renderer.setPixelRatio( window.devicePixelRatio );
    window.renderer.setSize( document.body.clientWidth, document.body.clientHeight);
    window.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    window.renderer.toneMappingExposure = 1;
    // window.renderer.outputEncoding = THREE.sRGBEncoding;
    //resizeRendererToDisplaySize(window.renderer,canvasDom)
    // this.renderer.setClearColor('rgba(0,0,0,0.5)',1.0);
    return window.renderer
}

function resizeRendererToDisplaySize(cdom){
    if(window.renderer){
        window.renderer.setSize(cdom.clientWidth,cdom.clientHeight,false)
    }
}

function canvasRenderResize(canvasDom){
    canvasDom.width = document.getElementById("middle3DContent").clientWidth
        //let h = document.getElementById("middle3DContent").clientHeight
    canvasDom.style.width = canvasDom.width + 'px'

    canvasDom.height = canvasDom.width
    canvasDom.style.height = `${canvasDom.width}px`
    resizeRendererToDisplaySize(canvasDom)
}

export {getRenderFrame,resizeRendererToDisplaySize,canvasRenderResize}

