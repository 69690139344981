import axios from './middleware'

export const getDesignQrcode = async () => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/open/design/qrcode`)
    return data;
}

export const getDesignScanResult = async (uuid) => {
    let data = await axios.get(`${process.env.REACT_APP_API_HOST}/open/design/scan/result?uuid=${uuid}`)
    return data;
}

export const getPatternList = async function(args){
    return await axios.post(`${process.env.REACT_APP_API_HOST}/auth/pattern/list`,args)
}

export const getOption = async() => {
    let ret = await fetch(`https://files.dizanz.com/options.json?${new Date().getTime()}`)
    return await ret.json()
}

export const createDiyPattern = async function(args){
    return await axios.post(`${process.env.REACT_APP_API_HOST}/auth/pattern/diy/create`,args)
}

export const getDiyPatternList = async function(){
    return await axios.get(`${process.env.REACT_APP_API_HOST}/auth/pattern/diy/list`)
}

export const getNeedDetail = async function(id){
    return await axios.get(`${process.env.REACT_APP_API_HOST}/auth/need/detail?id=${id}`)
}

export const getFashionFabric = async function(fashion_id){
    return await axios.get(`${process.env.REACT_APP_API_HOST}/auth/fashion/fabric/list?fashion_id=${fashion_id}`)
}

export const getCutpartsByNeedId = async function(need_id){
    return await axios.get(`${process.env.REACT_APP_API_HOST}/auth/need/cutparts?need_id=${need_id}`)
}

export const getCutpartsCalculateStatusByNeedId = async function(need_id){
    return await axios.get(`${process.env.REACT_APP_API_HOST}/auth/need/cutparts/calculate/status?need_id=${need_id}`)
}

export const updateCutparts = async function(args){
    return await axios.post(`${process.env.REACT_APP_API_HOST}/auth/need/cutparts/update`, args)
}

export const createOrder = async function(args){
    return await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/create`, args)
}

export const previewOrder = async function(args){
    return await axios.post(`${process.env.REACT_APP_API_HOST}/auth/order/create?preview=1`, args)
}

export const reCalculate = async function(needId){
    return await axios.post(`${process.env.REACT_APP_API_HOST}/auth/calculate/afresh?need_id=${needId}`)
}

export const getLineupNum = async function(minId){
    return await axios.get(`${process.env.REACT_APP_API_HOST}/auth/calculate/lineup?min_id=${minId}`)
}

export const abortCalculate = async function(needId){
    return await axios.post(`${process.env.REACT_APP_API_HOST}/auth/calculate/abort?need_id=${needId}`)
}

export const get_fashion_detail = async function(fashion_id){
    return await axios.get(`${process.env.REACT_APP_API_HOST}/auth/fashion/detail?id=${fashion_id}`)
}

export const get_fashion_cutpart = async function(fashion_id){
    return await axios.get(`${process.env.REACT_APP_API_HOST}/auth/fashion/cutpart/list?id=${fashion_id}`)
}
